@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    @variants responsive {
        .masonry {
            column-count: 1;
        }
        .masonry-sm {
            column-count: 2;
        }
        .masonry-md {
            column-count: 3;
        }
        .break-inside {
            break-inside: avoid;
        }

    }
}
@layer utilities {
    @variants responsive {
        .vidmasonry {
            column-count: 1;
        }
        .vidmasonry-sm {
            column-count: 1;
        }
        .vidmasonry-md {
            column-count: 2;
        }
        .break-inside {
            break-inside: avoid;
        }

    }
}

@font-face {
    font-family: Larken;
    src: url(./fonts/larken/LarkenDEMO-Bold.otf);
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.loding_container {
  width: 100%;
  height: 100%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #273617;
  animation: anim2 1s cubic-bezier(0.77, 0, 0.175, 1) 5s both;
}
@keyframes anim2 {
  to {
    transform: translateY(-100%);
  }
}
.thumbs{
  text-align: center;
}
.thumb{
  border: 3px solid #333 !important;
}
.selected{
  border: 3px solid #ffffff !important;
}
.carousel-root{
  max-width: 95vw;
}
._2uc4w{
  padding: 13px;
}
._siqLL{
  display: none;
}
._3XP0H{
  background-color: white !important;;
}
.bggg{
  background: url("http://www.farmofhappiness.com/images/Full-Sky.jpg") top center repeat-y;
  height: 450px;

}

.bggg{
  background-position: 0 0 0 50%;
}
